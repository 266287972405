<template>
<div>
    <div :class="`${colorCard} ${cardSize}`">
    <b-col
        :class="typeCard === 'cardTransaction' ? 
          'text-white d-flex flex-column justify-content-center col-8' 
          : 'text-white d-flex flex-column justify-content-center pl-2 col-8'"
        class="p-1"
    >
        <div>
        <b-badge class="badge-go"><span class="badge-text">{{ textBadge }}</span></b-badge>
        </div>
        <div :class="typeCard === '' ? 'mt-1' : 'mt-1'">
            <span :class="typeCard === 'cardTransaction'? 'text-information-trasaction' : 'text-information'">{{ textInfo }}</span>
        </div>
        <div v-if="!disabledButton" :class="typeCard === '' ? 'container-link mt-1' : 'container-link'">
          <b-button variant="light" @click="reference" >{{ link }}</b-button>
        </div>
    </b-col>
    <b-col class="col-4 position-relative">
        <div :class="`${position_img} images-inner-first`">
            <b-img :src="img" :class="`${img_go} opacity-25`"/>
        <b-img :src="img" :class="img_go"/>
        <b-img :src="img" :class="`${img_go} opacity-25`"/>
        </div>
    </b-col>
    </div>
</div>
</template>
    
<script>
import { BBadge, BCol, BImg, BButton } from "bootstrap-vue";

export default {
  name: "cardGoPro",
  components: {
    BBadge,
    BCol,
    BImg,
    BButton,
  },
  props: {
    disabledButton: {
      type: Boolean,
      default: false
    },
    textBadge: {
      type: String,
      default: ''
    },
    textInfo: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    colorCard: {
      type: String,
      default: 'color-card'
    },
    img: {
      type: String,
      default: ''
    },
    cardSize: {
      type: String,
      default: 'card-go'
    },
    img_go: {
      type: String,
      default: 'img-go'
    },
    position_img: {
      type: String,
      default: 'position-img'
    },
    typeCard: {
      type: String,
      default: ''
    },
    reference: {
      type: Function,
      default: () => {} 
    },
  },
};
</script>

<style scoped>
.card-secondary {
  background: linear-gradient(to left bottom, #fcd751, #e94287) !important;
}
.color-card {
  background-image: linear-gradient(to left bottom, #743eb5, #421150);
}  
.container-link:hover {
  color: rgba(243, 243, 243, 0.582);  
}  
.link {
  color: #55a6c4; 
  font-size: 1.1em;
}
.link-transaction {
  color: #55a6c4; 
  font-size: 1em;
}    
.badge-text {
  font-size: 20px;
  font-weight: none;
}
.text-information {
  font-size: 1.9em;
  line-height: 1.2;
}
.text-information-trasaction {
  font-size: 1em;
  line-height: 1.2;
}
.card-go {
  min-height: 215px;
  border-radius: 1.5em;
  display: flex;
  overflow: hidden;
}
.badge.badge-go {
  font-weight: normal !important;
  background-color: rgba(249, 250, 252, 0.2) !important;
  border-radius: 25px;
  padding: 12px;
}
.img-go {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1em;
  border-radius: 100%;
}
.images-inner-first {
  position: absolute;
  display: flex;
  flex-direction: column;
}
.position-img {
  top: -9.3em;
}
.opacity-25 {
  opacity: 0.25;
}
</style>